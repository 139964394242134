<template>
  <v-btn
    v-if="create"
    @click="save"
    v-hotkey="saveKeymap"
  >
    <span>{{'action.save'| t}} (Ctrl+S)</span>
    <v-badge :color="statusColor" dot>
      <v-icon>fa fa-save</v-icon>
    </v-badge>
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import formActionMixins from '@/components/form/formAction/formActionMixins'
export default {
  mixins: [formActionMixins],
  beforeDestroy() {
    this.cancelPreventDefaultSave()
  },
  created() {
    this.preventDefaultSave()
  },
  computed: {
    create() {
      if(this.loadFailure) return false
      if(this.dataAction.save === false) return false
      return true
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>